






















import Vue from 'vue';
import Recommended from '@/components/Recommended.vue';
import Article from '@/components/ArticleItem.vue';

export default Vue.extend({
	components: { Recommended, Article },
	data (): any {
		return {
			articles: []
		};
	},
	beforeMount () {
		this.fetchCategory();
		this.fetchArticles();
	},
	watch: {
		'$route.params.slug' (slug) {
			this.articles = [];
			this.fetchCategory();
			this.fetchArticles();
		}
	},
	computed: {
		id (): any { return this.$route.params.slug.match(/^\d+/)[0]; },
		category (): any { return this.$store.getters.category || {}; }
	},
	methods: {
		fetchCategory () {
			this.$store.dispatch('fetch', {
				fragment: `category/${this.id}/`,
				storeKey: 'category',
				endpoint: 'content',
				store: true
			});
		},
		fetchArticles () {
			this.$store.dispatch('fetch', {
				params: { category: this.id },
				fragment: 'article/',
				endpoint: 'content'
			}).then((res:any) => {
				Array.from(res.data).forEach(article => {
					this.articles.push(article);
				});
			});
		}
	}
});
